function setCookie(name, value, daysToExpire) {
    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + (daysToExpire * 24 * 60 * 60 * 1000))
    const cookieString = `${name}=${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}; path=/`
    document.cookie = cookieString
}

function getCookie(name) {
    const cookieString = document.cookie
    const cookies = cookieString.split(';')
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()
        const [cookieName, cookieValue] = cookie.split('=')
        if (cookieName === name)
            return decodeURIComponent(cookieValue)
    }
    return null // Cookie with the specified name was not found
}

export { setCookie, getCookie }
