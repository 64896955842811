import { useTranslation } from "react-i18next";
import ComingSoon from "./ComingSoon";

const Landing = () => {
  const { t, i18n } = useTranslation();
  const isArabicLang= i18n.language==="ar";

  return (
    <div
      className={`landing-wrap flex flex-col items-center md:min-h-[48em]`}
      id="home"
    >
      <div className={`${isArabicLang ? "" : "font-['blockhead']"}`}>
        <div className={"landing font-bold text-white flex flex-col justify-center items-center mt-[6em] md:mt-[12.5em]"}>
          <h2 className="text-2xl custom-bottom-border py-4 w-72 text-center uppercase glow">
            {t('landing.headers.top')}
          </h2>
          <h1 className={`${isArabicLang? "mb-4 text-[4em] md:text-[5em]": "my-4 text-[5em] md:text-[7em]"} uppercase text-[#BF9F4A] glow gold`}>
            {t('landing.headers.center')}
          </h1>
          <h2 className="custom-top-border text-2xl py-4 w-72 text-center uppercase glow">
            {t('landing.headers.bottom')}
          </h2>
        </div>
      </div>
      <div className="z-40">
        <ComingSoon />
      </div>
    </div>
  );
};

export default Landing;
