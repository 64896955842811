import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const Blogs = () => {
  const blogsData = [
    {
      id: 1,
      title: "blogs.blogs.1.title",
      Content: 'blogs.blogs.1.contnet',
      cover: "images/blogs/blog-1.png",
      path: "/",
    },
    {
      id: 2,
      title: "blogs.blogs.2.title",
      Content: 'blogs.blogs.2.contnet',
      cover: "images/blogs/blog-2.png",
      path: "/",
    },
    {
      id: 3,
      title: "blogs.blogs.3.title",
      Content: 'blogs.blogs.3.contnet',
      cover: "images/blogs/blog-3.png",
      path: "/",
    },
  ];

  const { t, i18n } = useTranslation();
  const isArabicLang= i18n.language==="ar";
  
  return (
    <div id="blogs" className={`flex flex-col mx-auto p-auto relative ${isMobile? "": "items-center"} `} style={{ direction: isArabicLang ? "rtl" : "ltr" }}>
      <div className='items-center'>
        <h1 className={`flex pb-8 font-bold mx-5 text-xl md:mt-[7em] text-white`}>
          {t("blogs.section")}
        </h1>
      </div>
      <div className={`flex overflow-x-scroll pb-10 hide-scroll-bar relative `}>
        <div className="flex flex-nowrap items-center">
          {blogsData.map((blog) => (
            <div className="inline-block px-3 md:px-10 xxl:px-[5em]" key={blog.id}>
              <div className={`w-64 lg:w-96 h-[28em] max-w-xs min-h-full overflow-hidden blog flex flex-col  p-3 bg-[#252525] ${isArabicLang? "text-right": "text-left"}`}>
                <img
                  src={`${process.env.REACT_APP_PATH}/${blog.cover}`}
                  alt={blog.title}
                  className={isMobile ? `w-[100%]` : ""}
                />
                <h2 className="title font-bold my-3 lg:my-5 text-[1.0em] text-white self-start">
                  {t(blog.title)}
                </h2>
                <p className="content text-white items-center max-w-2 text-[0.6em] mb-4">
                  {t(blog.Content)}
                </p>
                {/* <div className="my-4 lg:my-9 self-end">
                  <LinkBtn link={blog.path} variant="OUTLINE">
                    See More
                  </LinkBtn>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
