import React, { useState, useEffect } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import ReactPlayer from "react-player/lazy";
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";


export default function ResponsiveCarousel(props) {
  const ref = React.useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
  const onCenterSlideDataIndexChange = (newIndex: number) => {
    setCenterSlideDataIndex(newIndex);
  };
  const { t, i18n } = useTranslation();
  
  const { setContentHandler, data } = props;
  setContentHandler(data[centerSlideDataIndex]);

  return (
    <div
      id="missions"
      style={{ width: "25em", position: "relative", marginLeft: i18n.language==="ar"? "-19em":"19em" }}
    >
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 1;
          if (parentWidth <= 1440) currentVisibleSlide = 2;
          if (parentWidth <= 720) currentVisibleSlide = 1;
          return (
            <>
              <StackedCarousel
                ref={carouselRef}
                slideComponent={Card}
                height={isMobile ? 200 : 490}
                slideWidth={parentWidth < 550 ? parentWidth - 10 : 620}
                carouselWidth={parentWidth}
                data={data}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={1}
                onActiveSlideChange={onCenterSlideDataIndexChange}
                useGrabCursor
                disableSwipe
              />
            </>
          );
        }}
      />
      <div className={`${isMobile ? "mb-4" : ""}`}>
        <img
          alt="back"
          color="secondary"
          onClick={() => {
            // @ts-ignore
            ref.current?.goBack();
          }}
          id="soonBackButton"
          className="backButton"
          src={`${process.env.REACT_APP_PATH}/back.png`}
        ></img>
        <img
          alt="forward"
          color="secondary"
          onClick={() => {
            // @ts-ignore
            ref.current?.goNext(6);
          }}
          id="soonForwardButton"
          className="forwardButton"
          src={`${process.env.REACT_APP_PATH}/forward.png`}
        ></img>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {data.map((_, index) => {
                const isCenterSlide = centerSlideDataIndex === index;
                return <div style={{ height: 5, width: isCenterSlide? 45: 15, borderRadius: '10%', background: isCenterSlide ? '#BF9F4A' : '#BF9F4A', transition: "all 0.5s" }}/>
            })}
      </div>
    </div>
  );
}

export const Video = React.memo(function (props) {
  // @ts-ignore
  const { data, dataIndex } = props;
  const { cover, link } = data[dataIndex];

  return (
    <ReactPlayer
      key={dataIndex}
      height={isMobile ? 140 : 450}
      width={isMobile ? 500 : 700}
      light={<img className="" src={`${process.env.REACT_APP_PATH}/${cover}`} alt="Thumbnail" />}
      url={link}
    />
  );
});

export const Card = React.memo(function (props) {
  // @ts-ignore
  const { data, dataIndex } = props;
  const { cover } = data[dataIndex];
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        userSelect: "none",
      }}
      className="my-slide-component"
    >
      <img
        alt="mission"
        style={{
          position: "relative",
          objectFit: "cover",
          borderRadius: 0,
        }}
        draggable={false}
        className="border border-[#BF9F4A]"
        src={`${process.env.REACT_APP_PATH}/${cover}`}
      />
    </div>
  );
});

