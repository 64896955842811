import { useTranslation } from "react-i18next";
import { BiLogoFacebook } from "react-icons/bi";
import { FaYoutube, FaTiktok, FaInstagram, FaFacebook } from "react-icons/fa6";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const isArabicLang= i18n.language==="ar";

  return (
    <>
      <nav className="w-full text-white md:border-b border-white" style={{direction: isArabicLang? "rtl": "ltr"}}>
        <div className="justify-between px-4 py-2  md:items-center md:flex md:px-8">
          <div>
            <div className="md:flex hidden items-center justify-between flex-row-reverse ">
              <div className="flex ">
                <a href="#home">
                  <h1 className="text-2xl font-bold flex items-center relative">
                    <img src={`${process.env.REACT_APP_PATH}/main-logo.png`} className="nav-logo" alt="logo" />
                    <span className={`hidden md:block text-[24px] text-white ${isArabicLang? "": "tracking-wider font-[blockhead]"}`}>
                      {t("footer.logoName")}
                    </span>
                  </h1>
                </a>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block hidden md:pb-0 md:mt-0`}
            >
              <ul className="items-center justify-center space-y-8 md:flex md:space-x-20 uppercase text-sm md:space-y-0">
                {/* <li className={`text-white hover:text-[#BF9F4A] ${isArabicLang? "ml-[6em]": ""}`}>
                  <a href="#home">{t("footer.navbar.home")}</a>
                </li>
                <li className="text-white hover:text-[#BF9F4A]">
                  <a href="#blogs">{t("footer.navbar.about")}</a>
                </li> */}
                {/* <li className="text-white hover:text-[#BF9F4A]">
                  <a href={`${process.env.REACT_APP_PATH}/comingSoon.html`}>{t("footer.navbar.gameFeatures")}</a>
                </li> */}
                {/* <li className="text-white hover:text-[#BF9F4A]">
                  <a href="#subscribe">{t("footer.navbar.subscribe")}</a>
                </li> */}
              </ul>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 block
        }`}
            >
              <ul className="items-center justify-center flex space-x-6 space-y-0">
                <li className={`${isArabicLang? "ml-[1em]": ""}`}>
                  <a href="https://www.facebook.com/profile.php?id=61552037909749" target= "_blank">
                    <BiLogoFacebook size={20} />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/sinai.heroes/?fbclid=IwAR2Cm9k0i7mKudR63o_oP2B3flM24-gwKWUiEs1cCNVWayKsC8_fBq0_n48" target= "_blank">
                    <FaInstagram size={20} />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCeLGRezuPCySM7muwR51BIA" target= "_blank">
                    <FaYoutube size={20} />
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@sinai.heroes" target= "_blank">
                    <FaTiktok size={20} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div
        className={`md:flex flex-row-reverse justify-between flex-1 justify-self-center m-4 block text-center`}
      >
        <ul className="items-center md:justify-end justify-center flex uppercase text-sm md:space-y-0">
          <li className="text-white hover:text-[#BF9F4A] border-r px-2 border-white">
            <a href={`${process.env.REACT_APP_PATH}/privacy-en.html`}>Privacy Policy</a>
          </li>
          <li className="text-white hover:text-[#BF9F4A] px-2">
            <a href={`${process.env.REACT_APP_PATH}/privacy-ar.html`}>سياسة الخصوصية</a>
          </li>
        </ul>
        <a href="https://www.genesiscreations.co/" target="_blank" className="text-white my-2">
          {t("footer.copyrights")} &copy; Genesis Creations
        </a>
      </div>
    </>
  );
}
