import React, { useState, useEffect } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";

export const weaponsData = [
  {
    id: 1,
    title: "Mission 2 Comming soon",
    cover: "gifs/AK47.gif",
    link: "/",
  },
  {
    id: 2,
    title: "Play With Your Friends soon",
    cover: "gifs/Ammo Box.gif",
    link: "/",
  },
  {
    id: 3,
    title: "Mission 2 Comming soon",
    cover: "gifs/Grenade.gif",
    link: "/",
  },
  {
    id: 4,
    title: "Mission 2 Comming soon",
    cover: "gifs/Groyunov.gif",
    link: "/",
  },
  {
    id: 5,
    title: "Mission 2 Comming soon",
    cover: "gifs/Mine.gif",
    link: "/",
  },
  {
    id: 5,
    title: "Mission 2 Comming soon",
    cover: "gifs/Patton.gif",
    link: "/",
  },
];

export default function ResponsiveCarousel(props) {
  const ref = React.useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
  const onCenterSlideDataIndexChange = (newIndex: number) => {
    setCenterSlideDataIndex(newIndex);
  };
  const { t, i18n } = useTranslation();
  const isArabicLang= i18n.language==="ar";


  return (
    <div id="weaponry" style={{ width: "75%", position: "relative", margin: "auto" }}>
      <h1 className={`flex pb-8 font-bold mt-[6em] md:mt-[12em] xxl:mt[12em] text-xl text-white justify-center ${isMobile? "justify-center": ""}`}>
        {t("weaponry.section")}
      </h1>

      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 3;
          if (parentWidth <= 1440) currentVisibleSlide = 3;
          if (parentWidth <= 1440) currentVisibleSlide = 1;
          return (
            <>
              <StackedCarousel
                ref={carouselRef}
                slideComponent={Card}
                height={isMobile ? 200 : 250}
                slideWidth={parentWidth < 550 ? parentWidth - 10 : 420}
                carouselWidth={parentWidth}
                data={weaponsData}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={3}
                onActiveSlideChange={onCenterSlideDataIndexChange}
                useGrabCursor
                disableSwipe
              />
            </>
          );
        }}
      />
      <div className={`${isMobile ? "mb-4" : ""}`}>
        <img
          alt="back"
          color="secondary"
          onClick={() => {
            // @ts-ignore
            ref.current?.goBack();
          }}
          className="backButton"
          src={`${process.env.REACT_APP_PATH}/back.png`}
        ></img>
        <img
          alt="forward"
          color="secondary"
          onClick={() => {
            // @ts-ignore
            ref.current?.goNext(6);
          }}
          className="forwardButton"
          src={`${process.env.REACT_APP_PATH}/forward.png`}
        ></img>
      </div>
    </div>
  );
}

export const Card = React.memo(function (props) {
  // @ts-ignore
  const { data, dataIndex } = props;
  const { cover } = data[dataIndex];
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        userSelect: "none",
      }}
      className="my-slide-component"
    >
      <img
        alt="cover"
        style={{
          // height: "100%",
          // width: "10em",
          bottom: "5em",
          position: "relative",
          objectFit: "cover",
          borderRadius: 0,
        }}
        draggable={false}
        src={`${process.env.REACT_APP_PATH}/${cover}`}
      />
    </div>
  );
});
