import { useTranslation } from "react-i18next";
import DesktopCarousel from "../carousels/soonDesktopCarousel";
import MobileCarousel from "../carousels/soonMobileCarousel";
import { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';

/** 
 * data can't be defined inside react component function with current implementation
 * update it so data can be defined to use localization
 */

 const data = [
  {
    cover: "images/soonVideoThumbs/M-1.png",
    title: "teasers.missions.1.title",
    desc: "teasers.missions.1.description",
    footer: "teasers.missions.1.footer",
  },
 
  {
    cover: "images/soonVideoThumbs/M-3.png",
    title: "teasers.missions.3.title",
    desc: "",
    footer: "",
  },
];

const ComingSoon = () => {
  const [content, setContent] = useState({title: "", desc: "", footer: ""});
  const setContentHandler = (data) => {
    setContent(data);
  }
  const { t, i18n } = useTranslation();
  const isArabicLang= i18n.language==="ar";

  return (
    !isMobile?
    <div id="missions" className="w-96 h-96 relative mx-auto flex flex-col items-cente mt-[10em]">
      <div className={`absolute justify-start items-start gap-40 inline-flex ${isArabicLang? "ml-[21em]": "ml-[-21em]"} `}>
        <div className="w-96 h-80 relative">
          <div className={`w-96 h-28 left-0 top-0 absolute flex-col justify-start items-emd gap-6 ltr inline-flex ${isArabicLang? "rtl text-right": "ltr"}`}>
            <div className={`flex-col gap-1 flex ${isArabicLang? "justify-end": "justify-start"}`}>
            <div className="text-white text-[0.9em] font-bold tracking-wider">{t("teasers.header")}</div>
            <div className={`max-w-96 text-white text-[1.7em] font-bold  uppercase tracking-widest soon-header ${isArabicLang? "pb-2": "pb-1"}`}>{t(content.title)}</div>
            <div className="w-96 h-52 left-[8em] top-[11em] slider-contnet">
            <div className={`w-80 top-0 absolute text-white text-sm font-normal leading-snug ${isArabicLang? "text-right left-[4em]": "text-justify"}`}>{t(content.desc)}</div>
            <div className={`w-80 h-14 ${isArabicLang? "left-[18.5em] top-[3em]": "right-[3.9em] top-[4em]"} absolute`}>
              <div className={`absolute text-white text-sm font-normal ${isArabicLang? "text-right ": "text-left"}`}>{t(content.footer)}</div>
              {/* <div className="px-6 py-3 absolute border border-[#bf9f4a] justify-center items-center gap-1.5 inline-flex">
                <div className="text-center text-[#bf9f4a] text-2xl font-bold font-['Outfit'] capitalize">Learn more</div>
              </div> */}
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>
        <DesktopCarousel setContentHandler={setContentHandler} data={data} />
    </div>: <div id="missions"><MobileCarousel setContentHandler= {setContentHandler} data={data}/></div>
  );
};

export default ComingSoon;
