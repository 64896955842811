import { FC } from "react";

interface Props {
  link: string;
  children: any;
  variant?: "OUTLINE" | "SOLID" | "NAV";
}

const LinkBtn: FC<Props> = ({ children, link, variant = "SOLID" }) => {
  let cn = "";
  if (variant === "SOLID") {
    cn = "text-white bg-[#BF9F4A] mt-1 pb-[5px] pt-[4.5px] md:py-[5px] md:px-[32px] px-[50px] text-[0.5em] font-bold";
  }else if (variant === "NAV") {
    cn = "text-white bg-[#BF9F4A] mt-1 py-[10px] md:py-[10px] md:px-[32px] px-[50px] text-[0.7em] font-bold";
  } else if (variant === "OUTLINE") {
    cn =
      " text-[#BF9F4A] border border-[#BF9F4A] py-[12px] px-[32px] text-[0.8em]";
  }
  return (
    <a href={link} className={cn}>
      {children}
    </a>
  );
};

export default LinkBtn;
