import "./App.css";
import ComingSoon from "./components/landing/ComingSoonSection";
import Landing from "./components/landing/Landing";
import Navbar from "./components/navbar/Navbar";
import Newsletter from "./components/newsletter/Newsletter";
// import Carousel from "../components/Carousel";
import Footer from "./components/footer/Footer";
import VideoCarousel from "./components/carousels/VideoCarousel";
import BlogCarousel from "./components/carousels/BlogCarousel";
import WeaponCarousel from "./components/carousels/WeaponCarousel";
import { useTranslation } from "react-i18next";
import { getCookie } from "./utils/cookies";
import { useEffect } from "react";

function App() {
  const useTranslations = useTranslation()

  useEffect(()=>{
    const langCookie = getCookie('lang')
    const lng = langCookie ? langCookie : 'en'
    useTranslations.i18n.changeLanguage(lng)
  }, [])
  const { t, i18n } = useTranslation();
  const isArabicLang = i18n.language === "ar";
  
  return (
    <div className= {isArabicLang? 'font-["cairo"]': 'font-["outfit"]'}>
      <Navbar />
      <Landing />
      <main className="container mx-auto my-8">
          <VideoCarousel />
          <ComingSoon />
          <WeaponCarousel />
          <BlogCarousel />
          <Newsletter />
      </main>
      <footer>
          <Footer />
      </footer>
    </div>
  );
}

export default App;
