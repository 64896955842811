import React from "react";
import LinkBtn from "../common/LinkBtn";
import { useTranslation } from 'react-i18next';

const ComingSoon = () => {
  const { t, i18n } = useTranslation();
  const isArabicLang= i18n.language==="ar";
  

  return (
    <div className="flex justify-center items-center flex-col text-white text-4xl my-9 md:my-16">
      {/* <h2 className="mb-1 text-[0.5em] font-bold font-['outfit']">Coming Soon</h2> */}
      <h2 className={`mb-1 text-[0.5em] font-bold ${isArabicLang? "": "font-['outfit']"}`}>{t("landing.earlyAccess")}</h2>
      <div className="flex mt-1 gap-3 mb-3">
        <a href="https://play.google.com/store/apps/details?id=com.GenesisCreations.sinaiheroes" target="_blank" rel="noreferrer">
          <img className="w-32 h-full" src= {`${process.env.REACT_APP_PATH}/images/googlePlay.png`} alt="googleplay" />
        </a>
        <a href="https://apps.apple.com/us/app/sinai-heroes/id6467144418" target="_blank" rel="noreferrer">
          <img className="w-32 h-full" src= {`${process.env.REACT_APP_PATH}/images/appStore.png`} alt="appstore" />
        </a>
      </div>
      <LinkBtn link="#subscribe">{t("landing.subscribeButton")}</LinkBtn>
      {/* <div className="flex mt-3">
        <a href="/">
          <img className="w-32" src="/images/googlePlay.png" alt="googleplay" />
        </a>
        <a href="/">
          <img className="w-32" src="/images/appStore.png" alt="appstore" />
        </a>
      </div> */}
    </div>
  );
};

export default ComingSoon;
