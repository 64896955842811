import { FC, useState, useEffect, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";

type Props = {
  selected: string;
  data: any[];
  meta: {
    placeholder: string;
    searchStr: string;
  };
  setSelected: (country: string) => void;
};

export const Selector: FC<Props> = ({ selected, setSelected, data, meta }) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(e.target as Node)) {
        setOpen(false)
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="font-medium w-[13em]" ref={divRef}>
      <div
        onClick={() => setOpen(!open)}
        className={`bg-[#252525] w-full p-2 flex items-center justify-between rounded ${
          !selected && "text-gray-400"
        }`}
      >
        {selected
          ? selected?.length > 25
            ? selected?.substring(0, 25) + "..."
            : selected
          : meta.placeholder}
        <BiChevronDown size={20} className={`${open && "rotate-180"}`} />
      </div>
      <ul
        className={`bg-transparent mt-2 overflow-y-auto overflow-x-hidden ${
          open ? "max-h-60" : "max-h-0"
        } `}
      >
        {meta.searchStr && (
          <div className="flex items-center px-2 sticky top-0 bg-[#252525]">
            <AiOutlineSearch size={18} className="text-gray-700" />
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value.toLowerCase())}
              placeholder={meta.searchStr}
              className="p-2 outline-none bg-[#252525]"
            />
          </div>
        )}
        {data?.map((item) => (
          <li
            key={item?.name}
            className={`p-2 text-sm hover:bg-sky-600 hover:text-white
            ${
              item?.name?.toLowerCase() === selected?.toLowerCase() &&
              "bg-sky-600 text-[#252525]"
            }
            ${
              item?.name?.toLowerCase().startsWith(inputValue)
                ? "block"
                : "hidden"
            }`}
            onClick={() => {
              if (item?.name?.toLowerCase() !== selected.toLowerCase()) {
                setSelected(item?.name);
                setOpen(false);
                setInputValue("");
              }
            }}
          >
            {item?.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
