import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";



export default function ResponsiveCarousel(props) {
  const ref = React.useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
  const onCenterSlideDataIndexChange = (newIndex: number) => {
    setCenterSlideDataIndex(newIndex);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   if (centerSlideDataIndex === 2) setCenterSlideDataIndex(0);
  //   else( setCenterSlideDataIndex(centerSlideDataIndex+1))
  //   const intervalId = setInterval(() => {
  //   }, 1000); 

  //   return () => {
  //     clearInterval(intervalId); 
  //   };
  // }, [centerSlideDataIndex]); 

  const { setContentHandler, data } = props;
  setContentHandler(data[centerSlideDataIndex]);
  const { t, i18n } = useTranslation();
  const isArabicLang= i18n.language==="ar";

  return (
    <div style={{ width: "80%", position: "relative", margin: "auto" }}>
      <h1 className={`flex pb-8 font-bold mt-10 text-xl text-white ${isArabicLang? "mr-2 justify-end": ""} ${isMobile? "justify-center": ""}`}>
        {t("navbar.missions")}
      </h1>

      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 1;
          if (parentWidth <= 1440) currentVisibleSlide = 1;
          // if (parentWidth <= 1440) currentVisibleSlide = 1;
          if (parentWidth <= 200) currentVisibleSlide = 1;
          return (
            <>
              <StackedCarousel
                ref={carouselRef}
                slideComponent={Card}
                // height={isMobile ? 400 : 250}
                height={isMobile ? 340 : 250}
                slideWidth={parentWidth < 550 ? parentWidth - 40 : 420}
                carouselWidth={parentWidth}
                data={data}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={1}
                onActiveSlideChange={onCenterSlideDataIndexChange}
                useGrabCursor
                // disableSwipe
              />
            </>
          );
        }}
      />
      {/* <div className={`${isMobile ? "mb-4" : ""}`}>
        <img
          alt="back"
          color="secondary"
          onClick={() => {
            // @ts-ignore
            ref.current?.goBack();
          }}
          className="backButton"
          src={`${process.env.REACT_APP_PATH}/back.png`}
        ></img>
        <img
          alt="forward"
          color="secondary"
          onClick={() => {
            // @ts-ignore
            ref.current?.goNext(6);
          }}
          className="forwardButton"
          src={`${process.env.REACT_APP_PATH}/forward.png`}
        ></img>
      </div> */}
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {data.map((_, index) => {
                const isCenterSlide = centerSlideDataIndex === index;
                return <div style={{ height: 5, width: isCenterSlide? 45: 15, borderRadius: '10%', background: isCenterSlide ? '#BF9F4A' : '#BF9F4A', transition: "all 0.5s" }}/>
            })}
      </div>
    </div>
  );
}

export const Card = React.memo(function (props) {
  // @ts-ignore
  
  const { data, dataIndex } = props;
  const { t, i18n } = useTranslation();
  const isArabicLang= i18n.language==="ar";
  
  const { cover, title, desc } = data[dataIndex];
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        userSelect: "none",
      }}
      className="my-slide-component"
    >
      <img
        alt="cover"
        style={{
          position: "relative",
          objectFit: "cover",
          borderRadius: 0,
        }}
        className="border border-[#BF9F4A]"
        draggable={false}
        src={`${process.env.REACT_APP_PATH}/${cover}`}
      />
      <div className="w-96 h-80 relative bottom-[6em]" style={{direction: isArabicLang? "rtl": "ltr"}}>
          <div className="w-96 h-52 top-[1em] absolute">
            <div className={`w-80 max-w-[55%] top-8 absolute text-justify text-white text-[0.5em] font-normal font-['Open Sans'] leading-snug ${isArabicLang? "left-6": "left-4" }`}>{t(desc)}</div>
          </div>
          <div className={`w-96 h-28 top-0 absolute flex-col justify-start items-start gap-6 inline-flex  ${isArabicLang? "right-[8.8em]": "left-4"}`}>
            <div className="flex-col justify-start items-start gap-1 flex">
              <div className="max-w-[20em] text-white text-[0.7em] font-bold uppercase tracking-widest ">{t(title)}</div>
            </div>
          </div>
        </div>
    </div>
  );
});
