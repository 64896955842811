import React, { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import ReactPlayer from "react-player/lazy";
import { useTranslation } from "react-i18next";

export const data = [
  {
    cover: "images/videoThumbs/thumb-1.png",
    link: "https://youtu.be/sxH1CkRanWQ",
    isVideo: true
  },
  {
    cover: "images/videoThumbs/thumb-2.png",
    link: "https://youtu.be/sxH1CkRanWQ",
    isVideo: false
  },
  {
    cover: "images/videoThumbs/thumb-3.png",
    link: "https://youtu.be/sxH1CkRanWQ",
    isVideo: false
  },
];


export default function ResponsiveCarousel(props) {
  const ref = React.useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
  const onCenterSlideDataIndexChange = (newIndex: number) => {
    setCenterSlideDataIndex(newIndex);
  };
  const { t, i18n } = useTranslation();

  return (
    <div
      className= {data[centerSlideDataIndex]?.isVideo? "": "hidePlayButton" }
      id="news"
      style={{ width: "75%", position: "relative", margin: "auto", marginTop: "8em" }}
    >
      <h1 className="flex justify-center px-5 lg:mx-40 md:mx-20 mb-2 sm:mb-1 font-bold text-2xl text-white">
        {t("teasers.section")}
      </h1>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 3;
          if (parentWidth <= 1440) currentVisibleSlide = 3;
          if (parentWidth <= 720) currentVisibleSlide = 1;
          return (
            <>
              <StackedCarousel
                ref={carouselRef}
                slideComponent={Video}
                height={isMobile ? 250 : 280}
                slideWidth={parentWidth < 550 ? parentWidth - 10 : 620}
                carouselWidth={parentWidth}
                data={data}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={3}
                onActiveSlideChange={onCenterSlideDataIndexChange}
                useGrabCursor
                disableSwipe
              />
            </>
          );
        }}
      />
      <div className={`${isMobile ? "mb-4" : ""}`}>
        <img
          alt="back"
          color="secondary"
          onClick={() => {
            // @ts-ignore
            ref.current?.goBack();
          }}
          className="backButton"
          src={`${process.env.REACT_APP_PATH}/back.png`}
        ></img>
        <img
          alt="forward"
          color="secondary"
          onClick={() => {
            // @ts-ignore
            ref.current?.goNext(6);
          }}
          className="forwardButton"
          src={`${process.env.REACT_APP_PATH}/forward.png`}
        ></img>
      </div>
    </div>
  );
}

export const Video = React.memo(function (props) {
  // @ts-ignore
  const { data, dataIndex } = props;
  const { cover, link } = data[dataIndex];
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ReactPlayer
      key={dataIndex}
      height={isMobile ? 200 : 280}
      width={isMobile ? 500 : 700}
      light={<img className="border h-[9em] border-[#BF9F4A] md:h-[15em]" src={`${process.env.REACT_APP_PATH}/${cover}`} alt="Thumbnail" />}
      url={link}
      className={data[dataIndex]?.isVideo? "": "unclickable-video"}
    />
  );
});
