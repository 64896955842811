import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        lng: "en",
        resources: {
            en: {
                translations: require("./languages/en.json"),
            },
            ar: {
                translations: require("./languages/ar.json"),
            },
        },
        ns: ["translations"],
        defaultNS: "translations",

        // keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

i18n.languages = ["en", "ar"];

export default i18n;
