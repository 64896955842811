import { FormEvent, ReactNode, useState } from "react";
import countriesAr from "../../data/countries-ar.json";
import countriesEN from "../../data/countries-en.json";
import { Selector } from "../common/Selector";
import { BiLoaderCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState(18);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const LoaderIcon: ReactNode = <BiLoaderCircle />;

  const resetForm = () => {
    setSelectedCountry("");
    setSelectedGender("");
    setName("");
    setEmail("");
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!name || !email || !age || !selectedCountry || !selectedGender) {
      // Error state
      setIsSuccess(false);
      setIsError(true);
      return;
    }
    const user = {
      name,
      email,
      age,
      country: selectedCountry,
      gender: selectedGender,
    };

    try {
      setIsDisabled(true);
      await fetch(
        "https://c175oyps22.execute-api.us-east-1.amazonaws.com/sinai-heroes-website-data",
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
      setIsDisabled(false);
      return;
    }
    setIsDisabled(false);
    setIsError(false);
    setIsSuccess(true);
    resetForm();
  };

  const { t, i18n } = useTranslation();
  const isArabicLang= i18n.language==="ar";
  const CountryMeta = {
    placeholder: t("newsletter.form.fields.country.placeholder"),
    searchStr: t("newsletter.form.fields.country.searchPlaceholder"),
  };
  const [isDisabled, setIsDisabled] = useState(false);

  const tempDisableHandler = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 3000);
  };
  
  return (
    <section id="subscribe" className="newsletter container mx-auto mt-[9em]">
      <div className={`text-white md:flex flex-row-reverse md:gap-36 justify-between items-center relative  ${isArabicLang? "md:left-[11em]": "md:right-[11em]"}`} style={{direction: isArabicLang? "rtl": "ltr"}}>
        <div className={`md:w-1/3 md:m-0 m-auto pl-5 w-11/12 flex ${isArabicLang? "text-right": "text-left"} flex-col justify-between`}>
          <p className={`${isArabicLang? "": "font-[blockHead]"} capitalize my-3 font-bold tracking-[0.1em]`}>{t("newsletter.preHeader")}</p>
          <h1 className={`${isArabicLang? "": "tracking-[0.1em] font-[blockHead]"} uppercase mb-5 text-3xl font-extrabold leading-2`}>
            {t("newsletter.header")}
          </h1>
          <p className="capitalize leading-6 text-[0.6em] mb-1">
            {t("newsletter.clarification")}
          </p>
          <form
            className="flex flex-col gap-4 my-8"
            onSubmit={(e) => handleSubmit(e)}
          >
            {isError && (
              <p className="text-[#6e0404]">{t("newsletter.form.toast.error")}</p>
            )}
            {isSuccess && (
              <p className="text-[#206e04]">{t("newsletter.form.toast.success")}</p>
            )}
            <div className="flex gap-4 justify-between text-md items-center mr-2 ">
              <label htmlFor="name" className="text-[#BF9F4A] text-center">
                {t("newsletter.form.fields.name.label")}
              </label>
              <input
                className="bg-[#252525] px-4 py-2 placeholder:text-sm w-[13em]"
                type="text"
                minLength={3}
                maxLength={32}
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder= {t("newsletter.form.fields.name.placeholder")}
              />
            </div>
            <div className="flex gap-4 justify-between text-md items-center mr-2">
              <label htmlFor="country" className="text-[#BF9F4A] text-center">
                {t("newsletter.form.fields.country.label")}
              </label>
              <Selector
                selected={selectedCountry}
                setSelected={setSelectedCountry}
                data={isArabicLang? countriesAr: countriesEN}
                meta={CountryMeta}
              />
            </div>
            <div className="flex gap-4 justify-between text-md items-center mr-2">
              <label htmlFor="gender" className="text-[#BF9F4A] text-center">
                {t("newsletter.form.fields.gender.label")}
              </label>
              <Selector
                selected={selectedGender}
                setSelected={setSelectedGender}
                data={[{ name: isArabicLang? "ذكر": "Male" }, { name: isArabicLang? "انثي": "Female" }]}
                meta={{ placeholder: t("newsletter.form.fields.gender.placeholder"), searchStr: "" }}
              />
            </div>
            <div className="flex gap-4 justify-between text-md items-center mr-2">
              <label htmlFor="age" className="text-[#BF9F4A] text-center">
                {t("newsletter.form.fields.age.label")}
              </label>
              <input
                className="bg-[#252525] px-4 py-2 placeholder:text-sm w-[13em]"
                type="number"
                id="age"
                name="age"
                min={13}
                max={100}
                placeholder={t("newsletter.form.fields.age.placeholder")}
                value={age}
                onChange={(e) => setAge(+e.target.value)}
              />
            </div>
            <div className="flex gap-4 justify-between text-md items-center mr-2">
              <label htmlFor="age" className={`text-[#BF9F4A] ${isArabicLang? "text-[0.7em]": ""}  text-center`}>
                {t("newsletter.form.fields.email.label")}
              </label>
              <input
                className="bg-[#252525] px-4 py-2 placeholder:text-sm w-[13em]"
                type="email"
                id="email"
                name="email"
                placeholder={t("newsletter.form.fields.email.placeholder")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex gap-4 justify-end text-md font-bold items-center mr-2">
              <button type="submit" className={`${isDisabled? "bg-[#BF9F4A] py-3 px-[6em]": `bg-[#BF9F4A] py-2 ${isArabicLang? "px-[4.8em]": "px-[4.6em]"}`}`} disabled= {isDisabled}>
                {isDisabled? LoaderIcon: t("newsletter.form.registerButton")}
              </button>
            </div>
          </form>
        </div>
        <div className={`md:w-1/3 w-4/5 mx-auto md:block flex items-center gap-4 justify-center mt-10 ${isArabicLang? "mr-3 md:mr-[17em]": "font-['blockHead'] ml-6 md:ml-[17em]"}`}>
          {/* logo */}
          <img src={`${process.env.REACT_APP_PATH}/main-logo.png`} className="w-5/12 md:w-full" alt="logo" />
          <h1 className="md:hidden text-3xl">{t("newsletter.form.logoName")}</h1>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
